import React, { useEffect, useState } from 'react';
import { SlShare } from "react-icons/sl";
import './App.css';

const horariosPicoYPlaca = {
    inicio: "05:00",
    fin: "20:00"
};

const daysOfWeek = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

function App() {
    const [currentTime, setCurrentTime] = useState(new Date());
    const [countdown, setCountdown] = useState("");
    const [isPicoYPlacaActive, setIsPicoYPlacaActive] = useState(false);

    useEffect(() => {
        const timer = setInterval(() => {
            const now = new Date();
            setCurrentTime(now);
            updateCountdown(now);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const updateCountdown = (now) => {
        const [startHour, startMinute] = horariosPicoYPlaca.inicio.split(":").map(Number);
        const [endHour, endMinute] = horariosPicoYPlaca.fin.split(":").map(Number);

        const startTime = new Date(now);
        startTime.setHours(startHour, startMinute, 0, 0);

        const endTime = new Date(now);
        endTime.setHours(endHour, endMinute, 0, 0);

        let remainingTime;
        if (now < startTime) {
            remainingTime = startTime - now;
            setIsPicoYPlacaActive(false);
        } else if (now > endTime) {
            const tomorrowStartTime = new Date(startTime);
            tomorrowStartTime.setDate(tomorrowStartTime.getDate() + 1);
            remainingTime = tomorrowStartTime - now;
            setIsPicoYPlacaActive(false);
        } else {
            remainingTime = endTime - now;
            setIsPicoYPlacaActive(true);
        }

        const hours = Math.floor((remainingTime / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((remainingTime / (1000 * 60)) % 60);
        const seconds = Math.floor((remainingTime / 1000) % 60);

        setCountdown(`${hours}h ${minutes}m ${seconds}s`);
    };

    // Define or fetch picoYPlaca data here
    const picoYPlaca = {
        carros: [
            { dia: "Lunes", placas: "0 - 2" },
            { dia: "Martes", placas: "6 - 9" },
            { dia: "Miércoles", placas: "3 - 7" },
            { dia: "Jueves", placas: "4 - 8" },
            { dia: "Viernes", placas: "1 - 5" }
        ],
        motos: [
            { dia: "Lunes", placas: "0 - 2" },
            { dia: "Martes", placas: "6 - 9" },
            { dia: "Miércoles", placas: "3 - 7" },
            { dia: "Jueves", placas: "4 - 8" },
            { dia: "Viernes", placas: "1 - 5" }
        ]
    };

    const currentDay = daysOfWeek[currentTime.getDay()];

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Pico y Placa Medellín',
                    text: 'Consulta los horarios de Pico y Placa en Medellín.',
                    url: window.location.href,
                });
                console.log('Contenido compartido exitosamente');
            } catch (error) {
                console.error('Error al compartir', error);
            }
        } else {
            console.error('La API Web Share no está disponible en este navegador.');
        }
    };

    return (
        <div className="container mx-auto p-4 mt-4 lg:mt-12">
            <div className="bg-white md:shadow-lg md:rounded-lg md:border md:p-6">
                <div>
                    <h1 className="text-2xl lg:text-3xl mb-0 font-black text-center">Pico y Placa Medellín</h1>
                    <h1 className="text-xl lg:text-2xl mb-4 text-center border-b pb-2">Segundo Semestre 2024</h1>
                </div>
                <div className='flex flex-col lg:flex-row w-full items-center justify-center gap-4 mb-4'>
                    <h2 className="flex flex-col text-center rounded-lg shadow p-2 px-6 bg-gray-800 text-white" id="currentDay">
                        <span className='text-md font-medium'>Hoy</span>
                        <span className='text-2xl font-bold'>{currentDay} </span>
                    </h2>
                    <div className="text-center p-2 shadow border-2 bg-gray-100 rounded-lg px-6">
                        {currentDay === "Sábado" || currentDay === "Domingo" ? (
                            <p className="text-lg font-bold">No hay restricción de movilidad</p>
                        ) : (
                            <>
                                <p className="text-md">{isPicoYPlacaActive ? "Tiempo restante para finalizar:" : "Tiempo restante para iniciar:"}</p>
                                <p className="text-2xl font-bold">{countdown}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="p-4 border shadow rounded-lg">
                        <h2 className="text-2xl border-b pb-2 font-bold mb-4 text-center">Carros</h2>
                        <table className="table-auto w-full border-collapse border border-gray-200">
                            <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-4 py-2 border border-gray-200">Día</th>
                                    <th className="px-4 py-2 border border-gray-200">Placas</th>
                                </tr>
                            </thead>
                            <tbody id="carrosTable">
                                {picoYPlaca.carros.map((row) => (
                                    <tr key={row.dia} className={row.dia === currentDay ? "bg-yellow-100" : ""}>
                                        <td className="px-4 py-2 border border-gray-200">{row.dia}</td>
                                        <td className="px-4 py-2 border border-gray-200 text-center">{row.placas}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="p-4 border shadow rounded-lg">
                        <h2 className="text-2xl border-b pb-2 font-bold mb-4 text-center">Motos</h2>
                        <table className="table-auto w-full border-collapse border border-gray-200">
                            <thead>
                                <tr className="bg-gray-300">
                                    <th className="px-4 py-2 border border-gray-200">Día</th>
                                    <th className="px-4 py-2 border border-gray-200">Placas</th>
                                </tr>
                            </thead>
                            <tbody id="motosTable">
                                {picoYPlaca.motos.map((row) => (
                                    <tr key={row.dia} className={row.dia === currentDay ? "bg-yellow-100" : ""}>
                                        <td className="px-4 py-2 border border-gray-200">{row.dia}</td>
                                        <td className="px-4 py-2 border border-gray-200 text-center">{row.placas}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="mt-8 bg-yellow-100 p-4 rounded-md">
                    <h2 className="text-lg font-semibold">Excepciones</h2>
                    <p>Se exonerarán las vías de conexión regional y nacional: la Avenida Regional, Las Palmas y la vía a Occidente.</p>
                </div>
                <div className="mt-4 bg-blue-100 p-4 rounded-md">
                    <h2 className="text-lg font-semibold">Horario</h2>
                    <p>La medida comienza su implementación a partir del lunes 5 de agosto.</p>
                    <p>Horario: de 5:00 a.m. a 8:00 p.m., en jornada continua, en los 10 municipios del Valle de Aburrá.</p>
                </div>
            </div>
            <div className='flex justify-center mt-4'>
                <button
                    className='bg-gray-800 hover:bg-gray-700 text-xs text-white font-bold p-3 rounded-full'
                    onClick={handleShare}>
                    <SlShare size={22}/>
                </button>
            </div>
            <div className='p-4 text-sm text-gray-700 text-center'>2024 V2.0 Desarrollo Luis Cabezas
                <a href='https://cencomer.com' target='_blank' className='px-2 font-semibold'>
                    Cencomer.com
                </a>
            </div>
        </div>
    );
}

export default App;
